import React from 'react';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

import { GlobalStyles } from '../styles/theme';
import Header from './Header';
import { SettingsProvider } from './Context';
// import Footer from './Footer';

const ColorProvider = styled.div`
  background: var(--color-background);
  color: var(--color-text);
  transition: all 0.25s linear;
  min-height: 100vh;
`;

const Section = styled(motion.section)``;

const PageContents = styled(motion.div)`
  max-width: 1100px;
  margin: auto;
  padding: 1em;
`;

const Layout: React.FC = ({ children }) => {
  return (
    <SettingsProvider>
      <Helmet>
        <title>Just in Awe</title>
        <meta
          name="description"
          content="Justin Or's personal website and blog"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
      </Helmet>
      <GlobalStyles />
      <ColorProvider>
        <PageContents>
          <Header />
          <div>
            <AnimatePresence>
              <AnimateSharedLayout>
                <Section>{children}</Section>
              </AnimateSharedLayout>
            </AnimatePresence>
          </div>
          {/* <Footer /> */}
        </PageContents>
      </ColorProvider>
    </SettingsProvider>
  );
};

export default Layout;
