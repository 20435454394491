import React, { useContext } from 'react';
import {
  Grid as MGrid,
  Switch as MSwitch,
  Chip as MChip,
  TextField as MTextField,
  Card as MCard,
  Typography as MTypography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MyThemeProvider } from '../styles/theme';
import { SettingsContext } from '../components/Context';

const useStyles = makeStyles({
  root: {
    transition: 'background 0.25s linear',
  },
});

export const Grid = MGrid;

export const TextField = (props) => {
  const classes = useStyles();
  const { theme } = useContext(SettingsContext);
  return (
    <MyThemeProvider darkMode={theme}>
      <MTextField className={classes.root} {...props} />
    </MyThemeProvider>
  );
};

export const Switch = (props) => {
  const classes = useStyles();
  const { theme } = useContext(SettingsContext);
  return (
    <MyThemeProvider darkMode={theme}>
      <MSwitch color="primary" className={classes.root} {...props} />
    </MyThemeProvider>
  );
};

export const Chip = (props) => {
  const classes = useStyles();
  const { theme } = useContext(SettingsContext);
  return (
    <MyThemeProvider darkMode={theme}>
      <MChip className={classes.root} {...props} />
    </MyThemeProvider>
  );
};

export const Card = (props) => {
  const classes = useStyles();
  const { theme } = useContext(SettingsContext);
  return (
    <MyThemeProvider darkMode={theme}>
      <MCard className={classes.root} {...props} />
    </MyThemeProvider>
  );
};

export const Typography = (props) => {
  const classes = useStyles();
  const { theme } = useContext(SettingsContext);
  return (
    <MyThemeProvider darkMode={theme}>
      <MTypography className={classes.root} {...props} />
    </MyThemeProvider>
  );
};
