import React, { useState, useContext } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Switch, Typography } from './StyledMaterial';
import { Slide, useScrollTrigger, AppBar, Grid } from '@material-ui/core';

import { SettingsContext } from './Context';

const data = [
  { name: 'Home', to: '/' },
  { name: 'About', to: '/about' },
  { name: 'Projects', to: '/projects' },
  { name: 'Blog', to: '/blog' },
];

const StyledHeader = styled(AppBar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  font-size: calc(15px + 1vmin);
`;

const HeaderLayout = styled(motion.div)`
  width: 100%;
  max-width: 1100px;
`;

const StyledTitle = styled(Typography)`
  pointer-events: none;
  white-space: nowrap;
  margin: 10px;
`;

const StyledMenuIcon = styled(motion.div)`
  max-height: 2em;
  padding: 10px;
  z-index: 20;
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    display: none;
  }
`;

const MenuIcon = ({ onClick }: { onClick: () => void }) => (
  <StyledMenuIcon onClick={onClick}>
    <motion.svg width="40" height="40" viewBox="0 0 24 24">
      <motion.path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </motion.svg>
  </StyledMenuIcon>
);

const Backdrop = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: var(--color-backdrop);
  backdrop-filter: blur(5px);
`;

const MobileNavItem = styled(motion.li)`
  margin-left: 0.4em;
  display: flex;
  flex-direction: row;
  font-size: 30px;
  padding: 20px;
  position: relative;
  border-radius: 0.5em;
  margin: 20px;
`;

const BigNavigation = styled(motion.ol)`
  list-style: none;
  padding: 0px 0px 0px 20px;
  margin: 0;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.02,
    },
  },
};

const item = {
  visible: {
    x: 0,
    opacity: 1,
  },
  hidden: {
    x: -50,
    opacity: 0,
  },
};

const Underline = styled(motion.div)`
  width: 120%;
  left: -10%;
  height: 120%;
  top: -10%;
  border-radius: 5px;
  position: absolute;
  mix-blend-mode: difference;
  background-color: white;
  z-index: 11;
  pointer-events: none;
`;

const StyledLi = styled(motion.li)`
  margin-left: 0.4em;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  background-image: none;
  text-shadow: none;
  color: var(--color-text);
`;

const MyHeader = () => {
  const { page, setPage, theme, setTheme } = useContext(SettingsContext);
  const [isOpen, setOpen] = useState(false);

  const trigger = useScrollTrigger({
    target: typeof window !== 'undefined' ? window : undefined,
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <StyledHeader
        variant="elevation"
        // elevation={1}
        style={{
          background: 'var(--color-background)',
          transition: 'background 0.25s linear',
        }}
      >
        <HeaderLayout>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <StyledLink
                to={'/'}
                onClick={() => {
                  setPage('/');
                }}
              >
                <StyledTitle variant="h4">Justin Or</StyledTitle>
              </StyledLink>
            </Grid>
            <Grid item>
              <MenuIcon
                onClick={() => {
                  setOpen(!isOpen);
                }}
              />
            </Grid>
            <AnimatePresence>
              {isOpen && (
                <Backdrop
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={list}
                  onClick={() => setOpen(false)}
                >
                  <StyledLink
                    to="/"
                    onClick={() => {
                      setPage('/');
                      setOpen(false);
                    }}
                  >
                    <MobileNavItem variants={item} key={'home'}>
                      {' '}
                    </MobileNavItem>
                  </StyledLink>
                  {data.map(({ name, to }, i) => {
                    return (
                      <StyledLink
                        to={to}
                        onClick={() => {
                          setPage(to);
                          setOpen(false);
                        }}
                      >
                        <MobileNavItem
                          variants={item}
                          key={i}
                          style={{
                            backgroundColor:
                              to === page ? 'var(--color-text)' : 'none',
                            color:
                              to === page ? 'var(--color-background)' : 'none',
                          }}
                        >
                          {name}
                        </MobileNavItem>
                      </StyledLink>
                    );
                  })}
                  <MobileNavItem variants={item}>
                    <Switch
                      checked={theme === 'light' ? false : true}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        e.stopPropagation()
                      }
                      onChange={() => {
                        setTheme(theme === 'light' ? 'dark' : 'light');
                      }}
                    />
                  </MobileNavItem>
                </Backdrop>
              )}
            </AnimatePresence>
            <AnimateSharedLayout>
              <BigNavigation>
                {data.map(({ name, to }, i) => (
                  <StyledLi key={i} initial={{ fontWeight: 'normal' }}>
                    {to === page && (
                      <Underline
                        layoutId="underline"
                        // transition={{ type: 'spring', stiffness: '30' }}
                      />
                    )}
                    <StyledLink
                      to={to}
                      onClick={() => {
                        setPage(to);
                      }}
                    >
                      {name}
                    </StyledLink>
                  </StyledLi>
                ))}
                <StyledLi style={{ width: '58px' }}>
                  <Switch
                    checked={theme === 'light' ? false : true}
                    onChange={() => {
                      setTheme(theme === 'light' ? 'dark' : 'light');
                    }}
                  />
                </StyledLi>
              </BigNavigation>
            </AnimateSharedLayout>
          </Grid>
        </HeaderLayout>
      </StyledHeader>
    </Slide>
  );
};

export default styled(MyHeader)`
  @media (min-width: 600px) {
    min-height: 30vh;
  }
`;
